<template>
  <div style="padding-bottom: 200px">
    <form @submit.prevent="submitHandler" v-form-enter v-if="!isLoading">
      <div class="field col-6 justify-content-start">
        <h2>Contact Detail</h2>

        <div v-if="obj !== null" class="font-light text-sm cursor-pointer">
          {{ this.obj.company_name }}
        </div>
      </div>
      <div
        class="formgrid p-fluid grid justify-content-around align-items-center"
      >
        <Divider class="m-2 mb-5"></Divider>
        <div class="field col-12 sm:col-6">
          <label for="name">Title</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.title"
              placeHolder="Title"
            />
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label>Name</label>
          <div class="p-inputgroup">
            <InputText v-model="contactInfo.contact.name" placeHolder="Name" />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.contactInfo.contact.name.required"
          >
            Name is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Surname</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.surname"
              placeHolder="Surname"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.contactInfo.contact.surname.required"
          >
            Surname is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label class="label">Email</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.email"
              placeHolder="Email"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.contactInfo.contact.email.required"
          >
            Email is required
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="!$v.contactInfo.contact.email.email"
          >
            Please enter a valid email
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Designation</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.designation"
              placeHolder="Designation"
            />
          </div>
          <!-- <div
            class="error-validation-text pt-1"
            v-if="
              submitted &&
              !$v.contactInfo.contact.designation.required
            "
          >
            Designation is required
          </div> -->
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Department</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.department"
              placeHolder="Department"
            />
          </div>
          <!-- <div
            class="error-validation-text pt-1"
            v-if="
              submitted &&
              !$v.contactInfo.contact.department.required
            "
          >
            Department is required
          </div> -->
        </div>

        <div class="field col-12 sm:col-6">
          <label class="label">Cellphone</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.cell_number"
              placeHolder="Cellphone number"
            />
          </div>
        </div>

        <div class="field col-12 sm:col-6">
          <label>Telephone</label>
          <div class="p-inputgroup">
            <InputText
              v-model="contactInfo.contact.tel_number"
              placeHolder="Telephone number"
            />
          </div>
        </div>
        <div class="field col-12 pl-0">
          <div class="field col-12 sm:col-6">
            <label>Ext</label>
            <div class="p-inputgroup">
              <InputText
                v-model="contactInfo.contact.extension"
                placeHolder="Extension"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <div>
          <Button type="submit"> SAVE </Button>
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>

    <RocketLoad :full="true" v-else />
  </div>
</template>
<script>
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import { state, fetchContactDetails } from "../../../services/data_service.js";
export default {
  name: "ContactSlider",
  components: {},
  data() {
    return {
      submitted: false,
      isLoading: null,
      loadingText: "Loading...",
      contactInfo: {
        contact: {
          name: null,
          email: null,
          tel_number: null,
          designation: null,
          surname: null,
          title: null,
          department: null,
          cell_number: null,
          extension: null,
        },
      },
    };
  },
  validations: {
    contactInfo: {
      contact: {
        name: {
          required,
        },
        surname: {
          required,
        },

        email: {
          required,
          email,
        },
      },
    },
  },
  async mounted() {
    this.$store.dispatch("setSliderLoader", true);
    await this.loadData();
    if (this.contactDetail) {
      this.contactInfo.contact.title = this.contactDetail.title;
      this.contactInfo.contact.name = this.contactDetail.name;
      this.contactInfo.contact.tel_number = this.contactDetail.tel;
      this.contactInfo.contact.extension = this.contactDetail.contact_ext;
      this.contactInfo.contact.cell_number = this.contactDetail.contact_cell;
      this.contactInfo.contact.department = this.contactDetail.department;
      this.contactInfo.contact.designation = this.contactDetail.designation;
      this.contactInfo.contact.email = this.contactDetail.email;
      this.contactInfo.contact.surname = this.contactDetail.surname;
    }
    this.$store.dispatch("setSliderLoader", false);
  },
  methods: {
    async loadData() {
      await fetchContactDetails(this.contactDetail_uid);
    },
    submitHandler() {
      let url;
      if (this.$route.path.includes("/supplier")) {
        url = this.selectedContact
          ? "/v1/costtype/update/supplier/contact/"
          : "/v1/costtype/create/supplier/contact/";
      } else {
        url = this.selectedContact
          ? "/v1/clients/update/contact/"
          : "/v1/clients/create/contact/";
      }
      this.isLoading = true;
      this.contactInfo.contact.company_name = this.obj.company_name;
      (this.submitted = true), this.$v.$touch();
      if (this.$v.$invalid) {
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the fields",
          life: 3000,
        });
      } else {
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              url +
              (this.selectedContact
                ? this.selectedContact.uid
                : this.$route.params.uid),
            JSON.stringify(this.contactInfo),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: `Contact ${
                this.selectedContact ? "updated" : "created"
              }`,
              life: 3000,
            });
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    contactTitles() {
      return state.contactTitles;
    },
    contactDetail() {
      return state.contactDetail;
    },
    contactDetail_uid() {
      return this.selectedObject.contact.uid;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    selectedContact() {
      return this.selectedObject.contact
        ? JSON.parse(JSON.stringify(this.selectedObject.contact))
        : null;
    },
    obj() {
      return this.selectedObject.obj;
    },
  },
};
</script>